.table thead th {
  color: #B9B7BD;
  font-weight: 500;
}

.table tbody tr {
  color: #2F1160;
  font-weight: 500;
}

.gap {
  margin-left: 50px;
}