$primaryColor: #2f1160;

.top-navbar {
  width: 100%;
  // background-color: aqua;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 35px 45px;
  margin: 0 0 10px 0;
  .button-links {
    width: 282px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .link {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: $primaryColor;
    }
    .link:hover {
      text-decoration: none;
    }
  }
  .button-links-single {
    width: 282px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .link {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: $primaryColor;
    }
    .link:hover {
      text-decoration: none;
    }
  }
  .logo-img {
    width: 200px;
  }
}

@media (max-width: 500px) {
  .top-navbar {
    padding: 35px 15px;
    .logo-img {
      width: 130px;
    }
  }
}
