$primaryColor: #2f1160;

.login-main-div {
  min-height: calc(100vh - 120px);
  max-width: 650px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0 100px 0;
  h2 {
    font-size: 70px;
    line-height: 70px;
    font-weight: 700;
    color: $primaryColor;
    margin-bottom: 30px;
  }
  p {
    font-size: 25px;
    line-height: 38px;
    font-weight: 400;
    color: $primaryColor;
    margin-bottom: 30px;
  }
  .input-field {
    width: 300px;
    height: 40px;
    border: 1px solid $primaryColor;
    border-radius: 3px;
    margin: 15px 0;
  }
  ::placeholder {
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: $primaryColor;
    padding-left: 10px;
  }
  ::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: $primaryColor;
    padding-left: 10px;
  }
}

@media (max-width: 500px) {
  .login-main-div {
    background-size: unset;
    padding: 30px 0 215px 0;
    h2 {
      font-size: 45px;
    }
    p {
      font-size: 18px;
    }
  }
}
