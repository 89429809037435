$primaryColor: #2f1160;

.images-placeholder-main-div {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  border: 2px dashed $primaryColor;
  border-radius: 4px;
  padding: 10px;
  background-color: #f0f0f0;
  min-height: 150px;
  .images-placeholder-div {
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    border-radius: 4px;
  }
  .images-placeholder-div:hover {
    cursor: pointer;
  }
}

.images-placeholder-main-div::-webkit-scrollbar {
  -webkit-appearance: none;
}

.images-placeholder-main-div::-webkit-scrollbar:horizontal {
  height: 11px;
}

.images-placeholder-main-div::-webkit-scrollbar-thumb {
  border-radius: 8px;
  // border: 2px solid red;
  background-color: rgba(0, 0, 0, 0.37);
}
// .images-placeholder-main-div::-webkit-scrollbar-track {
//     background-color: red;
//     border-radius: 8px;
// }
