$primaryColor: #2F1160;

.button {
    background-color: $primaryColor;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    width: 107px;
    height: 50px;
    border: none;
    border-radius: 4px;
}

@media (max-width: 500px) {
    .button {
        font-size: 15px;
        width: 95px;
        height: 40px;
    }
}